import React, { createContext } from 'react'

import { Locale } from '../constants'

export const LocaleContext = createContext(
    Locale(process.env.STELE_LOCALE || 'en-US'),
)

export interface LocaleProviderProps {
    children: React.ReactNode
    value: Locale
}

export const LocaleProvider = ({ children, value }: LocaleProviderProps) => (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
)
