// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("./../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---techdebt-md": () => import("./../../TECHDEBT.md" /* webpackChunkName: "component---techdebt-md" */),
  "component---docs-how-stele-works-mdx": () => import("./../../docs/how_stele_works.mdx" /* webpackChunkName: "component---docs-how-stele-works-mdx" */),
  "component---docs-how-to-development-mdx": () => import("./../../docs/how_to/development.mdx" /* webpackChunkName: "component---docs-how-to-development-mdx" */),
  "component---docs-how-to-context-mdx": () => import("./../../docs/how_to/context.mdx" /* webpackChunkName: "component---docs-how-to-context-mdx" */),
  "component---docs-how-to-runtime-mode-mdx": () => import("./../../docs/how_to/runtime_mode.mdx" /* webpackChunkName: "component---docs-how-to-runtime-mode-mdx" */),
  "component---docs-how-to-simple-mdx": () => import("./../../docs/how_to/simple.mdx" /* webpackChunkName: "component---docs-how-to-simple-mdx" */),
  "component---docs-how-to-statically-known-mdx": () => import("./../../docs/how_to/statically-known.mdx" /* webpackChunkName: "component---docs-how-to-statically-known-mdx" */),
  "component---docs-how-to-ternaries-and-switch-mdx": () => import("./../../docs/how_to/ternaries_and_switch.mdx" /* webpackChunkName: "component---docs-how-to-ternaries-and-switch-mdx" */),
  "component---src-components-components-mdx": () => import("./../../src/components/components.mdx" /* webpackChunkName: "component---src-components-components-mdx" */),
  "component---src-components-currency-mdx": () => import("./../../src/components/currency.mdx" /* webpackChunkName: "component---src-components-currency-mdx" */),
  "component---src-components-datetime-mdx": () => import("./../../src/components/datetime.mdx" /* webpackChunkName: "component---src-components-datetime-mdx" */),
  "component---src-components-number-mdx": () => import("./../../src/components/number.mdx" /* webpackChunkName: "component---src-components-number-mdx" */),
  "component---src-components-plural-mdx": () => import("./../../src/components/plural.mdx" /* webpackChunkName: "component---src-components-plural-mdx" */),
  "component---src-components-select-mdx": () => import("./../../src/components/select.mdx" /* webpackChunkName: "component---src-components-select-mdx" */),
  "component---src-components-selectordinal-mdx": () => import("./../../src/components/selectordinal.mdx" /* webpackChunkName: "component---src-components-selectordinal-mdx" */),
  "component---src-icu-print-ast-license-md": () => import("./../../src/icu/printAST/LICENSE.md" /* webpackChunkName: "component---src-icu-print-ast-license-md" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

