import React, { useContext } from 'react'

import { LocaleContext } from '../components/localecontext'
import { createMessageFormatter } from '../dunder'
import { plural, PluralValue, PluralOptions } from '../factories/plural'

export function Plural(props: PluralProps): JSX.Element {
    const { value, ...options } = props
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return <>{messageFormatter(plural(options), { value })}</>
}

export interface PluralProps extends PluralOptions {
    /**
     * The number you are looking to format with a plural noun
     */
    value: PluralValue
}

/**
 * <Plural value={appleCount} zero="No apples" one="An apple" other="# apples" />
 */
