import {
    CurrencyCode,
    DEFAULT_LOCALE,
    NonUSDollar,
    SymbolPlacedToRight,
} from '../constants'
import { formatCurrencyToParts } from './utils'

export const isNonUSDollar = (currency: CurrencyCode): boolean =>
    currency in NonUSDollar

export const isSymbolToRightCurrency = (currency: CurrencyCode): boolean =>
    currency in SymbolPlacedToRight

function getParts(
    value: number,
    formatter: Intl.NumberFormat,
): Intl.NumberFormatPart[] {
    // formatToParts is a required property on Intl.NumberFormat.prototype
    // according to the spec, but since we sometimes delete it in tests, we're
    // going out of spec compliance. We cast the type here to reflect this
    // change.
    if (
        (
            Intl.NumberFormat.prototype as Partial<
                typeof Intl.NumberFormat.prototype
            >
        ).formatToParts
    ) {
        return formatter.formatToParts(value)
    } else {
        return formatCurrencyToParts(value, formatter)
    }
}

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
enum Notation {
    compact = 'compact',
    engineering = 'engineering',
    scientific = 'scientific',
}

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
enum CompactDisplay {
    short = 'short',
    long = 'long',
}

interface NumberFormatOptions extends Intl.NumberFormatOptions {
    // partial browser support exists for these, eg:
    // new Intl.NumberFormat('en-GB', { notation: "compact" , compactDisplay: "short" }).format(987654321)
    // -> 988M
    notation?: Notation
    compactDisplay?: CompactDisplay
}

/**
 * Function type that will take the result of `formatToParts' and alter it to customize the formatting.
 */
export type PostCurrencyFormatFunction = (
    currency: CurrencyCode,
    locale: string,
    parts: Intl.NumberFormatPart[],
) => void

export interface CurrencyFormatOptions extends NumberFormatOptions {
    alwaysShowDollarName?: boolean // TODO what does this do
    locale?: string
    truncateDecimal?: boolean
    postFormatHook?: PostCurrencyFormatFunction
}

function getOptions(
    value: number,
    currency: CurrencyCode,
    options?: CurrencyFormatOptions,
): NumberFormatOptions {
    const formatOptions: NumberFormatOptions = {
        ...options,
        style: 'currency',
        currency,
    }
    if (options?.truncateDecimal && value % 1 === 0) {
        formatOptions.maximumFractionDigits = 0
        formatOptions.minimumFractionDigits = 0
    }
    return formatOptions
}

// non breaking space
const nbsp = global.String.fromCharCode(160)

function showDollar(
    currency: CurrencyCode,
    _locale: string,
    parts: Intl.NumberFormatPart[],
): void {
    if (currency === CurrencyCode.USD || isNonUSDollar(currency)) {
        const currencyPart = parts.find(item => item.type === 'currency')
        if (currencyPart === parts[0]) {
            currencyPart.value = `${currency}${nbsp}$`
        } else if (currencyPart === parts[parts.length - 1]) {
            currencyPart.value = `$${nbsp}${currency}`
        }
    }
}

function generateOutput(
    value: number,
    currency: CurrencyCode,
    locale: string,
    formatter: Intl.NumberFormat,
    hooks: PostCurrencyFormatFunction[],
): string {
    const parts = getParts(value, formatter)
    for (const hook of hooks) {
        hook(currency, locale, parts)
    }

    // TODO: we might be able to just remove this entirely
    if (isSymbolToRightCurrency(currency)) {
        if (parts.length && parts[0].type === 'currency') {
            const currencySymbol = parts.shift()
            if (currencySymbol !== undefined) {
                currencySymbol.value = `${nbsp}${currencySymbol.value}`
                parts.push(currencySymbol)
            }
        }
    }

    return parts.reduce((prev, curr) => prev + (curr.value || ''), '')
}

export function formatCurrency(
    value: number,
    currency: CurrencyCode,
    options?: CurrencyFormatOptions,
): string {
    const locale = options?.locale || process.env.STELE_LOCALE || DEFAULT_LOCALE
    const formatOptions = getOptions(value, currency, options)
    const formatter = new Intl.NumberFormat(locale, formatOptions)

    const hooks: PostCurrencyFormatFunction[] = []

    if (options?.alwaysShowDollarName) {
        hooks.push(showDollar)
    }

    if (options?.postFormatHook) {
        hooks.push(options.postFormatHook)
    }

    return generateOutput(value, currency, locale, formatter, hooks)
}
