interface Separators {
    decimal: string
    group: string
}
const currencySeparators: Record<string, Separators> = {}
const nanReplacement = '©'

function getSeparators(formatter: Intl.NumberFormat) {
    const opts = formatter.resolvedOptions()
    // TODO (legacied no-prototype-builtins)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line no-prototype-builtins
    if (!currencySeparators.hasOwnProperty(opts.locale)) {
        const oneThousandPointOne = new Intl.NumberFormat(opts.locale).format(
            1000.1,
        )
        currencySeparators[opts.locale] = {
            decimal: oneThousandPointOne[5],
            group: oneThousandPointOne[1],
        }
    }
    return currencySeparators[opts.locale]
}
/**
 * Function to imitate the Intl.NumberFormat.formatToParts function.
 * It can be used to format currency values when formatToParts isn't
 * available.
 * NOTE: This is only for currency values, not numbers in general,
 * which is why it's not polyfilling the formatToParts function.
 * @param value The number to format.
 * @param formatter The Intl.NumberFormatter to use.
 */
export function formatCurrencyToParts(
    value: number,
    formatter: Intl.NumberFormat,
): Intl.NumberFormatPart[] {
    const separators = getSeparators(formatter)
    const parts: Intl.NumberFormatPart[] = []
    const fullStr = formatter.format(value).replace('NaN', nanReplacement)

    let currentObj: null | Intl.NumberFormatPart = null
    let hasDecimal = false
    let hasNan
    for (let a of fullStr) {
        const aType =
            a === nanReplacement
                ? 'nan'
                : a === separators.decimal
                ? 'decimal'
                : a === separators.group
                ? 'group'
                : // eslint-disable-next-line id-denylist
                a === String.fromCharCode(160)
                ? 'literal' // 160 == nbsp
                : isNaN(Number(a))
                ? 'currency'
                : hasDecimal
                ? 'fraction' // If we've already had the decimal point, then it's a fraction.
                : 'integer'

        if (aType === 'decimal') {
            hasDecimal = true
        } else if (aType === 'nan') {
            a = 'NaN'
            hasNan = true
        }

        if (currentObj === null) {
            currentObj = { type: aType, value: a }
        } else if (currentObj.type === aType) {
            currentObj.value += a
        } else {
            parts.push(currentObj)
            currentObj = { type: aType, value: a }
        }
    }

    if (currentObj !== null) {
        parts.push(currentObj)
    }

    if (hasNan) {
        return parts.filter(
            item => item?.type === 'currency' || item?.type === 'nan',
        )
    }

    return parts
}
