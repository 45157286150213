/**
 * A function that given a key and category
 * returns a string to be used inside an ICU
 * compliant string
 */
// TODO (legacied @typescript-eslint/ban-types)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/ban-types
export type GetFromKeyAndCategory<CategoryMatcher extends object> = (
    key: keyof CategoryMatcher,
    category: CategoryMatcher,
) => string

/**
 *
 * @param key the current value from inside categoryMapper
 * @param categoryMapper An object that contains keys and values to show to the end user
 * @param getCategory A function that returns the category name from a key and a category mapper
 * @param getCategoryValue A function that returns the user facing string from a key and a category mapper
 */
// TODO (legacied @typescript-eslint/ban-types)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/ban-types
export function generateICUOption<CategoryMatcher extends object>(
    key: keyof CategoryMatcher,
    categoryMapper: CategoryMatcher,
    getCategory: GetFromKeyAndCategory<CategoryMatcher>,
    getCategoryValue: GetFromKeyAndCategory<CategoryMatcher>,
): string {
    return categoryMapper[key]
        ? `${getCategory(key, categoryMapper)} {${getCategoryValue(
              key,
              categoryMapper,
          )}}`
        : ''
}

// TODO (legacied @typescript-eslint/ban-types)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/ban-types
export function generateICUOptions<CategoryMatcher extends object>(
    categoryMapper: CategoryMatcher,
    getCategory: GetFromKeyAndCategory<CategoryMatcher> = key =>
        // Since CategoryMatcher extends object, this means that keys might be
        // Symbols instead of strings (which don't coerce cleanly to strings and
        // are thus disallowed in TypeScript). In practice we don't support
        // Symbols though, so we just cast to string
        `${key as string}`,
    getCategoryValue: GetFromKeyAndCategory<CategoryMatcher> = (
        key,
        category,
    ) => `${category[key]}`,
): string {
    // TODO (legacied @typescript-eslint/no-unsafe-assignment)
    // This failure is legacied in and should be updated. DO NOT COPY.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const keys: Array<keyof CategoryMatcher> = Object.keys(
        categoryMapper,
        // TODO (legacied @typescript-eslint/no-explicit-any)
        // This failure is legacied in and should be updated. DO NOT COPY.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any
    return keys
        .reduce(
            (accumulatedValue, key) => `${accumulatedValue}
${generateICUOption(key, categoryMapper, getCategory, getCategoryValue)}`,
            '',
        )
        .trim()
}
