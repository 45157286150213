import { generateICUOptions } from './generateICUOptions'

/**
 * A function that given a matches object, returns the select ICU string
 * for that pair.
 * @param matches A lookup object for a given value
 */
// TODO (legacied @typescript-eslint/consistent-indexed-object-style)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export function select<T extends { [key: string]: string }>(
    matches: T,
    variableName?: string,
): string
// TODO (legacied @typescript-eslint/consistent-indexed-object-style)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export function select<T extends { [key: number]: string }>(
    matches: T,
    variableName?: string,
): string
// TODO (legacied @typescript-eslint/ban-types)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line @typescript-eslint/ban-types
export function select(matches: object, variableName = 'value'): string {
    return `{${variableName}, select,
${generateICUOptions(matches)}
}`
}
