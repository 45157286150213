module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"fonts":{"body":"aktiv-grotesk","heading":"Walsheim"}},"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":false,"o":false,"open":false,"open-browser":false,"root":"/home/circleci/repo/.docz","base":"/","source":"./","src":"./","files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Stele","description":"A compile time internationalization library for javascript and webpack","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/circleci/repo","templates":"/home/circleci/repo/node_modules/docz-core/dist/templates","docz":"/home/circleci/repo/.docz","cache":"/home/circleci/repo/.docz/.cache","app":"/home/circleci/repo/.docz/app","appPackageJson":"/home/circleci/repo/package.json","gatsbyConfig":"/home/circleci/repo/gatsby-config.js","gatsbyBrowser":"/home/circleci/repo/gatsby-browser.js","gatsbyNode":"/home/circleci/repo/gatsby-node.js","gatsbySSR":"/home/circleci/repo/gatsby-ssr.js","importsJs":"/home/circleci/repo/.docz/app/imports.js","rootJs":"/home/circleci/repo/.docz/app/root.jsx","indexJs":"/home/circleci/repo/.docz/app/index.jsx","indexHtml":"/home/circleci/repo/.docz/app/index.html","db":"/home/circleci/repo/.docz/app/db.json"}},
    }]
