import { Formats } from 'intl-messageformat'
import React, { useContext } from 'react'

import { LocaleContext } from '../components/localecontext'
import { createMessageFormatter } from '../dunder'

import {
    numberFormat,
    Style,
    NumberFormatMaximumFractionDigits,
    NumberFormatMaximumSignificantDigits,
    NumberFormatMinimumFractionDigits,
    NumberFormatMinimumIntegerDigits,
    NumberFormatMinimumSignificantDigits,
    NumberFormatValue,
} from '../factories/number'

export function getFormatOptions(
    style: Style,
    options: NumberOptions,
): Partial<Formats> {
    return {
        // eslint-disable-next-line id-denylist
        number: {
            [style]: {
                style,
                ...options,
            },
        },
    }
}

export function Decimal(props: NumberProps): JSX.Element {
    const { value, ...options } = props
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return (
        <>
            {messageFormatter(
                numberFormat(Style.Decimal),
                { value },
                undefined,
                getFormatOptions(Style.Decimal, options),
            )}
        </>
    )
}

export function Percent(props: NumberProps): JSX.Element {
    const { value, ...options } = props
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return (
        <>
            {messageFormatter(
                numberFormat(Style.Percent),
                { value },
                undefined,
                getFormatOptions(Style.Percent, options),
            )}
        </>
    )
}

export interface NumberOptions {
    maximumFractionDigits?: NumberFormatMaximumFractionDigits
    maximumSignificantDigits?: NumberFormatMaximumSignificantDigits
    minimumFractionDigits?: NumberFormatMinimumFractionDigits
    minimumIntegerDigits?: NumberFormatMinimumIntegerDigits
    minimumSignificantDigits?: NumberFormatMinimumSignificantDigits
}

export interface NumberProps extends NumberOptions {
    value: NumberFormatValue
}
