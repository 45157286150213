import React, { useContext } from 'react'

import { LocaleContext } from '../components/localecontext'
import { createMessageFormatter } from '../dunder'

import {
    datetime,
    Type,
    DateTimeFormat,
    DateTimeValue,
} from '../factories/datetime'

export function DateTime({ format, type, value }: DateTimeProps): JSX.Element {
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return <>{messageFormatter(datetime(type, format), { value })}</>
}

DateTime.Type = Type

export interface DateTimeProps {
    format?: DateTimeFormat
    type: Type
    value: DateTimeValue
}

/**
 * <DateTime type="date" value={new Date()} format="short" />
 */
