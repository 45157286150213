const chars = {
    up: [
        '̍',
        '̎',
        '̄',
        '̅',
        '̿',
        '̑',
        '̆',
        '̐',
        '͒',
        '͗',
        '͑',
        '̇',
        '̈',
        '̊',
        '͂',
        '̓',
        '̈́',
        '͊',
        '͋',
        '͌',
        '̃',
        '̂',
        '̌',
        '͐',
        '̀',
        '́',
        '̋',
        '̏',
        '̒',
        '̓',
        '̔',
        '̽',
        '̉',
        'ͣ',
        'ͤ',
        'ͥ',
        'ͦ',
        'ͧ',
        'ͨ',
        'ͩ',
        'ͪ',
        'ͫ',
        'ͬ',
        'ͭ',
        'ͮ',
        'ͯ',
        '̾',
        '͛',
        '͆',
        '̚',
    ],
    middle: [
        '̕',
        '̛',
        '̀',
        '́',
        '͘',
        '̡',
        '̢',
        '̧',
        '̨',
        '̴',
        '̵',
        '̶',
        '͏',
        '͜',
        '͝',
        '͞',
        '͟',
        '͠',
        '͢',
        '̸',
        '̷',
        '͡',
        '҉',
    ],
    down: [
        '̖',
        '̗',
        '̘',
        '̙',
        '̜',
        '̝',
        '̞',
        '̟',
        '̠',
        '̤',
        '̥',
        '̦',
        '̩',
        '̪',
        '̫',
        '̬',
        '̭',
        '̮',
        '̯',
        '̰',
        '̱',
        '̲',
        '̳',
        '̹',
        '̺',
        '̻',
        '̼',
        'ͅ',
        '͇',
        '͈',
        '͉',
        '͍',
        '͎',
        '͓',
        '͔',
        '͕',
        '͖',
        '͙',
        '͚',
        '̣',
    ],
} as const

const all = [...chars.up, ...chars.middle, ...chars.down] as const
export const allZalgoChars: () => RegExp = () =>
    RegExp('(' + all.join('|') + ')', 'g')

function upMarkForChar(c: string): string {
    const index = c.charCodeAt(0) % chars.up.length
    return chars.up[index]
}

function downMarkForChar(c: string): string {
    const index = c.charCodeAt(0) % chars.down.length
    return chars.down[index]
}

function middleMarkForChar(c: string): string {
    const index = c.charCodeAt(0) % chars.middle.length
    return chars.middle[index]
}

export function charWithMarks(c: string): string {
    const choose2 = [
        [upMarkForChar, downMarkForChar],
        [upMarkForChar, middleMarkForChar],
        [downMarkForChar, middleMarkForChar],
    ]
    const index = c.charCodeAt(0) % choose2.length

    return c + choose2[index][0](c) + choose2[index][1](c)
}

// simplified Zalgo for docs
export function Zalgo({ msg }: { msg: string }) {
    /* istanbul ignore next */
    return `[${msg.replace(/\w/g, charWithMarks)}]`
}
