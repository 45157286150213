import React, { useContext } from 'react'

import { LocaleContext } from '../components/localecontext'
import { createMessageFormatter } from '../dunder'
import {
    selectordinal,
    SelectOrdinalOptions,
    SelectOrdinalValue,
} from '../factories/selectordinal'

/**
 * @deprecated Currently, our translation vendor does not support translating to
 * SelectOrdinal. For now, prefer using <Select /> or reach out in internationalization
 * slack channels (#eng-i18n, #localization) for options.
 */
export function SelectOrdinal(props: SelectOrdinalProps): JSX.Element {
    const { value, ...options } = props
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return <>{messageFormatter(selectordinal(options), { value })}</>
}

export interface SelectOrdinalProps extends SelectOrdinalOptions {
    value: SelectOrdinalValue
}

/**
 * <SelectOrdinal value={5} zero="#th" one="#st" two="#nd" few="#rd" other="#th" />
 */
