// TODO (legacied import/no-named-as-default)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line import/no-named-as-default
import IntlMessageFormat, { Formats, PrimitiveType } from 'intl-messageformat'

const FORMATS = {
    date: {
        monthOnly: {
            month: 'long',
        },
        /**
         * @deprecated Use 'fullMonthShortDay' instead
         */
        monthWithDay: {
            month: 'long',
            day: 'numeric',
        },
        shortMonthShortDay: {
            month: 'numeric',
            day: 'numeric',
        },
        mediumMonthShortDay: {
            month: 'short',
            day: 'numeric',
        },
        fullMonthShortDay: {
            month: 'long',
            day: 'numeric',
        },
        mediumMonthWithYear: {
            month: 'short',
            year: 'numeric',
        },
        longMonthWithYear: {
            month: 'long',
            year: 'numeric',
        },
    },
    time: {
        hourOfDay: {
            hour: 'numeric',
        },
    },
} as const

export function createMessageFormatter(
    locale: string,
    options: Partial<Formats> = FORMATS,
) {
    return function formatMessageForLocale<
        FormatContext extends Record<string, PrimitiveType>,
    >(
        message: string,
        formatters?: FormatContext,
        _description?: string,
        innerOptions?: Partial<Formats>,
    ): string {
        const encodedMessage = new IntlMessageFormat(message, locale, {
            ...options,
            ...innerOptions,
        })
        return encodedMessage.format(formatters)
    }
}
export default createMessageFormatter(
    process.env.STELE_LOCALE || 'en-US',
    FORMATS,
)

export const translate = ((options?: Partial<Formats>) =>
    function formatMessageForLocale<
        FormatContext extends Record<string, PrimitiveType>,
    >(
        locale: string,
        message: string,
        formatters?: FormatContext,
        _description?: string,
        innerOptions?: Partial<Formats>,
    ): string {
        const encodedMessage = new IntlMessageFormat(message, locale, {
            ...options,
            ...innerOptions,
        })
        return encodedMessage.format(formatters)
    })(FORMATS)
