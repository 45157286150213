import React, { useContext } from 'react'

import { LocaleContext } from '../components/localecontext'
import { createMessageFormatter } from '../dunder'
import { select } from '../factories/select'

export type Match = string | number
export type MatchLookup<T extends Match> = { [Key in T]: string }

export function Select<KeyInMatches extends Match>(
    props: SelectProps<KeyInMatches>,
): JSX.Element {
    const { value, matches } = props
    const locale = useContext(LocaleContext)
    const messageFormatter = createMessageFormatter(locale)
    return <>{messageFormatter(select(matches), { value })}</>
}

export default Select

export interface SelectProps<KeyInMatches extends Match> {
    value: KeyInMatches
    matches: MatchLookup<KeyInMatches>
}

/**
 * <Select value={props.status} matches={{yes: "YEAH!", no: "Hell nah"}} />
 */
