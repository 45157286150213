import React, { createContext, useContext } from 'react'

import { CurrencyCode } from '../constants'
import { CurrencyFormatOptions, formatCurrency } from '../formatters'

export const CurrencyContext = createContext(CurrencyCode.USD)

export interface CurrencyProviderProps {
    children: React.ReactNode
    value: CurrencyCode
}

export const CurrencyProvider = ({
    children,
    value,
}: CurrencyProviderProps) => (
    <CurrencyContext.Provider value={value}>
        {children}
    </CurrencyContext.Provider>
)

export const useCurrency = () => useContext(CurrencyContext)

export function withCurrency<P>(
    // We want to make this capitalized, since it represents a React component
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component: React.ComponentType<P & { currency: CurrencyCode }>,
): React.ComponentType<P> {
    function WrapperComponent(props: P) {
        const currency = useCurrency()
        return <Component {...props} currency={currency} />
    }

    const name = `withCurrency(${Component.displayName || Component.name})`
    WrapperComponent.displayName = name

    return WrapperComponent
}

export interface CurrencyProps extends CurrencyFormatOptions {
    /**
     * The numerical value of the currency. Make sure you know whether
     * this value is in subunits. E.g, are you rendering 100 US cents, or 100 US dollars?
     * By default, numSubunits = 100 means value should be in cents.
     */
    value: number

    /**
     * The currency code to render
     */
    currency?: CurrencyCode

    /**
     * Divides the value into subunits. Almost all currencies use decimal subunits,
     * so we default to 100.
     * http://chartsbin.com/view/1378#:~:text=Subunit
     */
    numSubunits?: number
}

export function Currency({
    value,
    currency: currencyFromProps,
    alwaysShowDollarName,
    /* istanbul ignore next */
    locale = process.env.STELE_LOCALE,
    /* istanbul ignore next */
    truncateDecimal = true,
    numSubunits = 100,
    ...options
}: CurrencyProps) {
    const currencyFromContext = useCurrency()
    const currency = currencyFromProps || currencyFromContext
    const amountCents = value / numSubunits
    if (!currency) {
        throw new Error(
            'Missing a "currency" prop or <CurrencyProvider> ancestor',
        )
    }
    return (
        <>
            {formatCurrency(amountCents, currency, {
                alwaysShowDollarName,
                locale,
                truncateDecimal,
                ...options,
            })}
        </>
    )
}

export default Currency
