export function numberFormat(style: Style, variableName = 'value'): string {
    return `{${variableName}, number, ${style}}`
}

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
export enum Style {
    Currency = 'currency',
    Decimal = 'decimal',
    Percent = 'percent',
}

export type NumberFormatValue = number
export type NumberFormatMaximumFractionDigits = number
export type NumberFormatMaximumSignificantDigits = number
export type NumberFormatMinimumFractionDigits = number
export type NumberFormatMinimumIntegerDigits = number
export type NumberFormatMinimumSignificantDigits = number
