import { generateICUOptions } from './generateICUOptions'
import { PluralOptions } from './plural'

export type SelectOrdinalValue = number
export type SelectOrdinalOptions = PluralOptions

export function selectordinal(
    options: SelectOrdinalOptions,
    variableName = 'value',
): string {
    function getCategory(key: keyof SelectOrdinalOptions): typeof key | '=0' {
        return key === 'zero' ? '=0' : key
    }
    return `{${variableName}, selectordinal, 
${generateICUOptions(options, getCategory)}
}`
}
