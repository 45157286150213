/* istanbul ignore file */

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
export enum CurrencyCode {
    /**
     * Andorran Franc
     **/
    ADF = 'ADF',
    /**
     * Andorran Peseta
     **/
    ADP = 'ADP',
    /**
     * Utd. Arab Emir. Dirham
     **/
    AED = 'AED',
    /**
     * Afghanistan Old Afghani
     **/
    AFA = 'AFA',
    /**
     * Afghan Afghani
     **/
    AFN = 'AFN',
    /**
     * Albanian Lek
     **/
    ALL = 'ALL',
    /**
     * Armenian Dram
     **/
    AMD = 'AMD',
    /**
     * NL Antillian Guilder
     **/
    ANG = 'ANG',
    /**
     * Angolan Kwanza
     **/
    AOA = 'AOA',
    /**
     * Angolan Old Kwanza
     **/
    AON = 'AON',
    /**
     * Argentine Peso
     **/
    ARS = 'ARS',
    /**
     * Australian Dollar
     **/
    AUD = 'AUD',
    /**
     * Aruban Florin
     **/
    AWG = 'AWG',
    /**
     * Azerbaijan Old Manat
     **/
    AZM = 'AZM',
    /**
     * Azerbaijan New Manat
     **/
    AZN = 'AZN',
    /**
     * Bosnian Mark
     **/
    BAM = 'BAM',
    /**
     * Barbados Dollar
     **/
    BBD = 'BBD',
    /**
     * Bangladeshi Taka
     **/
    BDT = 'BDT',
    /**
     * Bulgarian Old Lev
     **/
    BGL = 'BGL',
    /**
     * Bulgarian Lev
     **/
    BGN = 'BGN',
    /**
     * Bahraini Dinar
     **/
    BHD = 'BHD',
    /**
     * Burundi Franc
     **/
    BIF = 'BIF',
    /**
     * Bermudian Dollar
     **/
    BMD = 'BMD',
    /**
     * Brunei Dollar
     **/
    BND = 'BND',
    /**
     * Bolivian Boliviano
     **/
    BOB = 'BOB',
    /**
     * Brazilian Real
     **/
    BRL = 'BRL',
    /**
     * Bahamian Dollar
     **/
    BSD = 'BSD',
    /**
     * Bhutan Ngultrum
     **/
    BTN = 'BTN',
    /**
     * Botswana Pula
     **/
    BWP = 'BWP',
    /**
     * Belarusian Ruble
     **/
    BYN = 'BYN',
    /**
     * Belarusian Old Ruble
     **/
    BYR = 'BYR',
    /**
     * Belize Dollar
     **/
    BZD = 'BZD',
    /**
     * Canadian Dollar
     **/
    CAD = 'CAD',
    /**
     * Congolese Franc
     **/
    CDF = 'CDF',
    /**
     * CFP Franc
     **/
    CFP = 'CFP',
    /**
     * Swiss Franc
     **/
    CHF = 'CHF',
    /**
     * Chilean Peso
     **/
    CLP = 'CLP',
    /**
     * Chinese Yuan Renminbi (offshore)
     **/
    CNH = 'CNH',
    /**
     * Chinese Yuan Renminbi
     **/
    CNY = 'CNY',
    /**
     * Colombian Peso
     **/
    COP = 'COP',
    /**
     * Costa Rican Colon
     **/
    CRC = 'CRC',
    /**
     * Serbian Old Dinar
     **/
    CSD = 'CSD',
    /**
     * Czechoslovak Koruna
     **/
    CSK = 'CSK',
    /**
     * Cuban Convertible Peso
     **/
    CUC = 'CUC',
    /**
     * Cuban Peso
     **/
    CUP = 'CUP',
    /**
     * Cape Verde Escudo
     **/
    CVE = 'CVE',
    /**
     * Czech Koruna
     **/
    CZK = 'CZK',
    /**
     * Djibouti Franc
     **/
    DJF = 'DJF',
    /**
     * Danish Krone
     **/
    DKK = 'DKK',
    /**
     * Dominican Peso
     **/
    DOP = 'DOP',
    /**
     * Algerian Dinar
     **/
    DZD = 'DZD',
    /**
     * Ecuador Sucre
     **/
    ECS = 'ECS',
    /**
     * Estonian Kroon
     **/
    EEK = 'EEK',
    /**
     * Egyptian Pound
     **/
    EGP = 'EGP',
    /**
     * Eritrean Nakfa
     **/
    ERN = 'ERN',
    /**
     * Ethiopian Birr
     **/
    ETB = 'ETB',
    /**
     * Euro
     **/
    EUR = 'EUR',
    /**
     * Fiji Dollar
     **/
    FJD = 'FJD',
    /**
     * Falkland Islands Pound
     **/
    FKP = 'FKP',
    /**
     * Great Britain Pound
     **/
    GBP = 'GBP',
    /**
     * Georgian Lari
     **/
    GEL = 'GEL',
    /**
     * Ghanian Old Cedi
     **/
    GHC = 'GHC',
    /**
     * Ghanaian New Cedi
     **/
    GHS = 'GHS',
    /**
     * Gibraltar Pound
     **/
    GIP = 'GIP',
    /**
     * Gambian Dalasi
     **/
    GMD = 'GMD',
    /**
     * Guinea Franc
     **/
    GNF = 'GNF',
    /**
     * Guatemalan Quetzal
     **/
    GTQ = 'GTQ',
    /**
     * Guyanan Dollar
     **/
    GYD = 'GYD',
    /**
     * Hong Kong Dollar
     **/
    HKD = 'HKD',
    /**
     * Honduran Lempira
     **/
    HNL = 'HNL',
    /**
     * Croatian Kuna
     **/
    HRK = 'HRK',
    /**
     * Haitian Gourde
     **/
    HTG = 'HTG',
    /**
     * Hungarian Forint
     **/
    HUF = 'HUF',
    /**
     * Indonesian Rupiah
     **/
    IDR = 'IDR',
    /**
     * Israeli New Shekel
     **/
    ILS = 'ILS',
    /**
     * Indian Rupee
     **/
    INR = 'INR',
    /**
     * Iraqi Dinar
     **/
    IQD = 'IQD',
    /**
     * Iranian Rial
     **/
    IRR = 'IRR',
    /**
     * Iceland Krona
     **/
    ISK = 'ISK',
    /**
     * Jamaican Dollar
     **/
    JMD = 'JMD',
    /**
     * Jordanian Dinar
     **/
    JOD = 'JOD',
    /**
     * Japanese Yen
     **/
    JPY = 'JPY',
    /**
     * Kenyan Shilling
     **/
    KES = 'KES',
    /**
     * Kyrgyzstanian Som
     **/
    KGS = 'KGS',
    /**
     * Cambodian Riel
     **/
    KHR = 'KHR',
    /**
     * Comoros Franc
     **/
    KMF = 'KMF',
    /**
     * North Korean Won
     **/
    KPW = 'KPW',
    /**
     * Korean Won
     **/
    KRW = 'KRW',
    /**
     * Kuwaiti Dinar
     **/
    KWD = 'KWD',
    /**
     * Cayman Islands Dollar
     **/
    KYD = 'KYD',
    /**
     * Kazakhstan Tenge
     **/
    KZT = 'KZT',
    /**
     * Lao Kip
     **/
    LAK = 'LAK',
    /**
     * Lebanese Pound
     **/
    LBP = 'LBP',
    /**
     * Sri Lanka Rupee
     **/
    LKR = 'LKR',
    /**
     * Liberian Dollar
     **/
    LRD = 'LRD',
    /**
     * Lesotho Loti
     **/
    LSL = 'LSL',
    /**
     * Lithuanian Litas
     **/
    LTL = 'LTL',
    /**
     * Latvian Lats
     **/
    LVL = 'LVL',
    /**
     * Libyan Dinar
     **/
    LYD = 'LYD',
    /**
     * Moroccan Dirham
     **/
    MAD = 'MAD',
    /**
     * Moldovan Leu
     **/
    MDL = 'MDL',
    /**
     * Malagasy Ariary
     **/
    MGA = 'MGA',
    /**
     * Malagasy Old Franc
     **/
    MGF = 'MGF',
    /**
     * Macedonian Denar
     **/
    MKD = 'MKD',
    /**
     * Myanmar Kyat
     **/
    MMK = 'MMK',
    /**
     * Mongolian Tugrik
     **/
    MNT = 'MNT',
    /**
     * Macau Pataca
     **/
    MOP = 'MOP',
    /**
     * Mauritanian Old Ouguiya
     **/
    MRO = 'MRO',
    /**
     * Mauritania Ouguiya
     **/
    MRU = 'MRU',
    /**
     * Mauritius Rupee
     **/
    MUR = 'MUR',
    /**
     * Maldive Rufiyaa
     **/
    MVR = 'MVR',
    /**
     * Malawi Kwacha
     **/
    MWK = 'MWK',
    /**
     * Mexican Peso
     **/
    MXN = 'MXN',
    /**
     * Mexican Old Peso
     **/
    MXP = 'MXP',
    /**
     * Malaysian Ringgit
     **/
    MYR = 'MYR',
    /**
     * Mozambique Old Metical
     **/
    MZM = 'MZM',
    /**
     * Mozambique New Metical
     **/
    MZN = 'MZN',
    /**
     * Namibia Dollar
     **/
    NAD = 'NAD',
    /**
     * Nigerian Naira
     **/
    NGN = 'NGN',
    /**
     * Nicaraguan Cordoba Oro
     **/
    NIO = 'NIO',
    /**
     * Norwegian Kroner
     **/
    NOK = 'NOK',
    /**
     * Nepalese Rupee
     **/
    NPR = 'NPR',
    /**
     * New Zealand Dollar
     **/
    NZD = 'NZD',
    /**
     * Omani Rial
     **/
    OMR = 'OMR',
    /**
     * Panamanian Balboa
     **/
    PAB = 'PAB',
    /**
     * Peruvian Nuevo Sol
     **/
    PEN = 'PEN',
    /**
     * Papua New Guinea Kina
     **/
    PGK = 'PGK',
    /**
     * Philippine Peso
     **/
    PHP = 'PHP',
    /**
     * Pakistan Rupee
     **/
    PKR = 'PKR',
    /**
     * Polish Zloty
     **/
    PLN = 'PLN',
    /**
     * Polish Old Zloty
     **/
    PLZ = 'PLZ',
    /**
     * Paraguay Guarani
     **/
    PYG = 'PYG',
    /**
     * Qatari Rial
     **/
    QAR = 'QAR',
    /**
     * Romanian Old Leu
     **/
    ROL = 'ROL',
    /**
     * Romanian New Leu
     **/
    RON = 'RON',
    /**
     * Serbian Dinar
     **/
    RSD = 'RSD',
    /**
     * Russian Rouble
     **/
    RUB = 'RUB',
    /**
     * Rwandan Franc
     **/
    RWF = 'RWF',
    /**
     * Saudi Riyal
     **/
    SAR = 'SAR',
    /**
     * Solomon Islands Dollar
     **/
    SBD = 'SBD',
    /**
     * Seychelles Rupee
     **/
    SCR = 'SCR',
    /**
     * Sudanese Old Dinar
     **/
    SDD = 'SDD',
    /**
     * Sudanese Pound
     **/
    SDG = 'SDG',
    /**
     * Sudanese Old Pound
     **/
    SDP = 'SDP',
    /**
     * Swedish Krona
     **/
    SEK = 'SEK',
    /**
     * Singapore Dollar
     **/
    SGD = 'SGD',
    /**
     * St. Helena Pound
     **/
    SHP = 'SHP',
    /**
     * Slovak Koruna
     **/
    SKK = 'SKK',
    /**
     * Sierra Leone Leone
     **/
    SLL = 'SLL',
    /**
     * Somali Shilling
     **/
    SOS = 'SOS',
    /**
     * Suriname Dollar
     **/
    SRD = 'SRD',
    /**
     * Suriname Old Guilder
     **/
    SRG = 'SRG',
    /**
     * Sao Tome/Principe Old Dobra
     **/
    STD = 'STD',
    /**
     * Sao Tome/Principe Dobra
     **/
    STN = 'STN',
    /**
     * El Salvador Colon
     **/
    SVC = 'SVC',
    /**
     * Syrian Pound
     **/
    SYP = 'SYP',
    /**
     * Swaziland Lilangeni
     **/
    SZL = 'SZL',
    /**
     * Thai Baht
     **/
    THB = 'THB',
    /**
     * Tajikistan Somoni
     **/
    TJS = 'TJS',
    /**
     * Turkmenistan Old Manat
     **/
    TMM = 'TMM',
    /**
     * Turkmenistan New Manat
     **/
    TMT = 'TMT',
    /**
     * Tunisian Dinar
     **/
    TND = 'TND',
    /**
     * Tongan Pa'anga
     **/
    TOP = 'TOP',
    /**
     * Turkish Old Lira
     **/
    TRL = 'TRL',
    /**
     * Turkish Lira
     **/
    TRY = 'TRY',
    /**
     * Trinidad/Tobago Dollar
     **/
    TTD = 'TTD',
    /**
     * Taiwan Dollar
     **/
    TWD = 'TWD',
    /**
     * Tanzanian Shilling
     **/
    TZS = 'TZS',
    /**
     * Ukraine Hryvnia
     **/
    UAH = 'UAH',
    /**
     * Uganga Old Shilling
     **/
    UGS = 'UGS',
    /**
     * Uganda Shilling
     **/
    UGX = 'UGX',
    /**
     * US Dollar
     **/
    USD = 'USD',
    /**
     * Uruguayan Old Peso
     **/
    UYP = 'UYP',
    /**
     * Uruguayan Peso
     **/
    UYU = 'UYU',
    /**
     * Uzbekistan Som
     **/
    UZS = 'UZS',
    /**
     * Venezuelan Bolivar
     **/
    VEB = 'VEB',
    /**
     * Venezuelan Bolivar Fuerte
     **/
    VEF = 'VEF',
    /**
     * Venezuelan Bolivar Soberano
     **/
    VES = 'VES',
    /**
     * Vietnamese Dong
     **/
    VND = 'VND',
    /**
     * Vanuatu Vatu
     **/
    VUV = 'VUV',
    /**
     * Samoan Tala
     **/
    WST = 'WST',
    /**
     * CFA Franc BEAC
     **/
    XAF = 'XAF',
    /**
     * East Carribean Dollar
     **/
    XCD = 'XCD',
    /**
     * CFA Franc BCEAO
     **/
    XOF = 'XOF',
    /**
     * CFP Franc
     **/
    XPF = 'XPF',
    /**
     * Yemen Rial
     **/
    YER = 'YER',
    /**
     * Yugoslavian Old Dinar
     **/
    YUN = 'YUN',
    /**
     * South African Rand
     **/
    ZAR = 'ZAR',
    /**
     * Zambian Kwacha
     **/
    ZMK = 'ZMK',
    /**
     * Zambian Kwacha
     **/
    ZMW = 'ZMW',
    /**
     * Zimbabwe Old Dollar
     **/
    ZWD = 'ZWD',
    /**
     * Zimbabwe Dollar
     **/
    ZWL = 'ZWL',
}

// non-exhaustive list of dollar currencies
// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
export enum NonUSDollar {
    AUD = CurrencyCode.AUD,
    CAD = CurrencyCode.CAD,
    NZD = CurrencyCode.NZD,
}

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
export enum SymbolPlacedToRight {
    NOK = CurrencyCode.NOK,
    DKK = CurrencyCode.DKK,
    SEK = CurrencyCode.SEK,
}
