export function datetime(
    type: Type,
    format: DateTimeFormat = 'medium',
    variableName = 'value',
): string {
    return `{${variableName}, ${type}, ${format}}`
}

// TODO (legacied no-restricted-syntax)
// This failure is legacied in and should be updated. DO NOT COPY.
// eslint-disable-next-line no-restricted-syntax
export enum Type {
    Date = 'date',
    Time = 'time',
}

export type DateTimeValue = Date
export type DateTimeFormat =
    | 'short'
    | 'medium'
    | 'long'
    | 'full'
    | 'hourOfDay'
    | 'monthOnly'
    | 'monthWithDay'
    | 'shortMonthShortDay'
    | 'mediumMonthShortDay'
    | 'fullMonthShortDay'
    | 'mediumMonthWithYear'
    | 'longMonthWithYear'
