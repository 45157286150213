import { generateICUOptions } from './generateICUOptions'

export type PluralValue = number
export interface PluralOptions {
    zero?: string
    one?: string
    two?: string
    few?: string
    many?: string
    other?: string
}

export function plural(options: PluralOptions, variableName = 'value'): string {
    function getCategory(key: keyof PluralOptions): typeof key | '=0' {
        return key === 'zero' ? '=0' : key
    }
    return `{${variableName}, plural, 
${generateICUOptions(options, getCategory)}
}`
}
